.page {
  position: relative;
  
  .content {
    [id] {
      scroll-margin-top: $header-height;
      
      [header-desktop=normal] & {
        scroll-margin-top: 0;
      }
    }
  }

  @include blur;
}

@import "_single";
@import "_special";
@import "_archive";
@import "_home";
@import "_404";
@import "_offline";
@import "_friends";
